import React, { useContext } from "react";
import "./styles/CookiePage.css";
import { Link } from "gatsby";
import { useState } from "react";
import FTbtn from "./FTbtn";
import icon from "../../assets/img/link-icon.png";
import { I18nContext } from "../../i18n";

function CookiePage(props) {
  const { translate } = useContext(I18nContext);
  const [onState, setOnState] = useState(true);

  const toggleHandler = () => {
    setOnState(!onState);
  };

  const saveLocalStorageHandler = () => {
    if (typeof localStorage !== undefined) {
      if (onState) {
        let consent = {
          date: new Date(),
          consent: "Manage-Cookie: ON",
          isAccept: true
        };
        localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
      } else {
        let consent = {
          date: new Date(),
          consent: "Manage-Cookie: OFF",
          isAccept: false
        };
        localStorage.setItem("cookieAcceptance", JSON.stringify(consent));
      }
    }
  };

  return (
    <div className="cookiePage">
      <h1>{translate("cookiePage.title")}</h1>
      {/* <div className="first-para"> */}
      <p>{translate("cookiePage.paragraph1")}</p>
      {/* </div> */}
      <hr />
      <h2>{translate("cookiePage.header")}</h2>
      {/* <div style={{ display: "block" }}> */}
      <p>{translate("cookiePage.paragraph2")}</p>
      <br></br>
      <p>{translate("cookiePage.paragraph25")}</p>
      {/* </div> */}
      <hr />
      <h2>{translate("cookiePage.header2")}</h2>
      <p>{translate("cookiePage.paragraph7")}</p>
      <br></br>
      <p>
        <strong>{translate("cookiePage.paragraph8")}</strong>
      </p>
      {/* <p>{translate("cookiePage.paragraph9")}</p> */}
      <br />
      <hr />
      <h2>{translate("cookiePage.header3")}</h2>
      <p>{translate("cookiePage.paragraph10")}</p>
      <br />
      <ul className="step3-third-party-list">
        <li>
          <a
            href="https://policies.google.com/technologies/cookies?hl=en"
            target="_blank"
          >
            <span style={{ color: "#0D7680" }}>Google (opens in new window)</span>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }} alt="open link icon"></img>
          </a>{" "}
          -{" "}
          <a
            href="https://adssettings.google.com/authenticated"
            target="_blank"
          >
            <span style={{ color: "#0D7680" }}>
              {translate("cookiePage.manageCookiesGoogle")} (opens in new window)
            </span>
            <img src={icon} style={{ marginLeft: "1px", width: "10px" }} alt="open link icon"></img>
          </a>
        </li>

      </ul>
      <hr />
      <h2>{translate("cookiePage.header4")}</h2>
      <p>{translate("cookiePage.paragraph11")}</p> <br></br>
      <p>{translate("cookiePage.paragraph12")}</p> <br></br>
      <p>{translate("cookiePage.paragraph13")}</p>
      <br />
      <table className="step2-table">
        <tbody>

          <tr>
            <th className="table__cell-heading">
              <a
                href="https://policies.google.com/technologies/ads"
                target="_blank"
              >
                <span style={{ color: "#0D7680" }}>Google (opens in new window)</span>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                  alt="open link icon"
                ></img>
              </a>
              <br />
              <a
                href="https://support.google.com/ads/answer/2662922?hl=en-GB"
                target="_blank"
              >
                <span style={{ color: "#0D7680" }}>
                  {translate("cookiePage.manageCookies")} (opens in new window)
                </span>
                <img
                  src={icon}
                  style={{ marginLeft: "1px", width: "10px" }}
                  alt="open link icon"
                ></img>
              </a>
            </th>
            <td className="table__cell-content">
              {translate("cookiePage.googleAds")}
            </td>
          </tr>
          <tr>
            <th className="table__cell-heading"></th>
            <td className="table__cell-content"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CookiePage;
